import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/layouts/ApiLayout.js";
import { graphql } from 'gatsby';
import ComponentApi from '../../components/ComponentApi';
import ReactPlayground from '../../components/ReactPlayground';
import SpinnerBasic from '../../examples/Spinner/Basic';
import SpinnerBorder from '../../examples/Spinner/Border';
import SpinnerGrow from '../../examples/Spinner/Grow';
import SpinnerVariants from '../../examples/Spinner/Variants';
import SpinnerSizes from '../../examples/Spinner/Sizes';
import SpinnerButtons from '../../examples/Spinner/Buttons';
export const query = graphql`
  query Spinner {
    metadata: componentMetadata(displayName: { eq: "Spinner" }) {
      displayName
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "spinners"
    }}>{`Spinners`}</h1>
    <p>{`Spinners can be used to show the loading state in your projects.`}</p>
    <ReactPlayground codeText={SpinnerBasic} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "animations"
    }}>{`Animations`}</h2>
    <p>{`Bootstrap offers two animation styles for spinners. The animation style
can be configured with the `}<inlineCode parentName="p">{`animation`}</inlineCode>{` property. An animation style
must always be provided when creating a spinner.`}</p>
    <p><strong parentName="p">{` Border Spinner - `}<inlineCode parentName="strong">{`border`}</inlineCode></strong></p>
    <ReactPlayground codeText={SpinnerBorder} mdxType="ReactPlayground" />
    <p><strong parentName="p">{` Grow Spinner - `}<inlineCode parentName="strong">{`grow`}</inlineCode>{` `}</strong></p>
    <ReactPlayground codeText={SpinnerGrow} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "variants"
    }}>{`Variants`}</h2>
    <p>{`All standard visual variants are available for both animation styles by
setting the `}<inlineCode parentName="p">{`variant`}</inlineCode>{` property. Alternatively spinners can be custom
sized with the `}<inlineCode parentName="p">{`style`}</inlineCode>{` property, or custom CSS classes.`}</p>
    <ReactPlayground codeText={SpinnerVariants} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "sizing"
    }}>{`Sizing`}</h2>
    <p>{`In addition to the standard size, a smaller additional preconfigured
size is available by configuring the `}<inlineCode parentName="p">{`size`}</inlineCode>{` property to `}<inlineCode parentName="p">{`sm`}</inlineCode>{`.`}</p>
    <ReactPlayground codeText={SpinnerSizes} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "buttons"
    }}>{`Buttons`}</h2>
    <p>{`Like the original Bootstrap spinners, these can also be used with
buttons. To use this component out-of-the-box it is recommended you
change the element type to `}<inlineCode parentName="p">{`span`}</inlineCode>{` by configuring the `}<inlineCode parentName="p">{`as`}</inlineCode>{` property when
using spinners inside buttons.`}</p>
    <ReactPlayground codeText={SpinnerButtons} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h2>
    <p>{`To ensure the maximum accessibility for spinner components it is
recommended you provide a relevant ARIA `}<inlineCode parentName="p">{`role`}</inlineCode>{` property,
and include screenreader-only readable text representation of the
spinner's meaning inside the component using Bootstrap's `}<inlineCode parentName="p">{`sr-only`}</inlineCode>{`
class.`}</p>
    <p>{`The example below provides an example of accessible usage of this
component.`}</p>
    <ReactPlayground codeText={SpinnerBasic} mdxType="ReactPlayground" />
    <h2 {...{
      "id": "api"
    }}>{`API`}</h2>
    <ComponentApi metadata={props.data.metadata} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      